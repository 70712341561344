import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { userProviderPropsMap } from '../../../User/userProviderPropsMap';
import {
  GetEligibleOrdersResponse,
  MemberOrdersByPlan,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { UserState } from '../../../User/UserContext';
import { getChallengeId } from '../../Challenge/helpers/getChallengeId';
import { isMockedChallenge } from '../../../main/getMockedChallenges';
import { eligibleOrders } from '../../../../api/benefits/eligibleOrders.api';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import { WarmupData } from '../../../../services/WarmupData';

const filterActivePlan = ({ planInfo }: any) =>
  planInfo.status === PlanStatus.ACTIVE;

const transformEligiblePlan = (eligPlan: MemberOrdersByPlan) => {
  if (eligPlan.memberOrders?.length) {
    eligPlan.memberOrders.forEach((order) => {
      if (order.validFrom && typeof order.validFrom === 'string') {
        /*
        We work with validFrom in context of date, not date+time.
        So some operation can work incorrectly with specific time, and because of it in this place time is replaced to first second of day.
        */
        order.validFrom = order.validFrom.replace(/T.*?Z/, 'T00:00:01.000Z');
      }

      return order;
    });
  }

  return eligPlan;
};

const WARMUPDATA_ELIGIBLE_ORDERS = 'WARMUPDATA_ELIGIBLE_ORDERS';

export async function getUserEligiblePlans(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
): Promise<MemberOrdersByPlan[]> {
  const warmupData = new WarmupData(flowAPI);
  const user = await userProviderPropsMap(flowAPI);
  challengeId = challengeId || (await getChallengeId(flowAPI));
  const ssrData = warmupData.get(
    `${WARMUPDATA_ELIGIBLE_ORDERS}_${challengeId}`,
  );

  if (
    !challengeId ||
    user.userType === UserState.VISITOR ||
    isMockedChallenge(challengeId, flowAPI)
  ) {
    return [];
  }

  try {
    const orders: GetEligibleOrdersResponse =
      ssrData ||
      ((
        await flowAPI.httpClient.request(
          eligibleOrders(user.user.id, challengeId),
        )
      )?.data?.orders as unknown as GetEligibleOrdersResponse);

    warmupData.set(`${WARMUPDATA_ELIGIBLE_ORDERS}_${challengeId}`, orders);

    return [
      ...orders.memberOrdersByPlan?.filter(filterActivePlan),
      ...orders.memberFutureOrdersByPlan?.filter(filterActivePlan),
    ]
      .filter((item) => !!item)
      .map(transformEligiblePlan);
  } catch (error) {
    handleError({
      context: 'getEligibleOrders',
      error,
    });

    return [];
  }
}
