import {
  benefitsList,
  IBenefitsListResponse,
} from '../../../../api/benefits/benefitsList.api';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  BenefitWithPlanInfo,
  Benefit,
  PlanInfo,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { WarmupData } from '../../../../services/WarmupData';

const transformUserPlan = (
  plan: PublicPlan,
  planInfo: PlanInfo,
  benefit: Benefit,
) => ({
  id: planInfo.id,
  details: {
    id: planInfo.id,
    name: planInfo.name,
    description: plan?.description || '',
  },
  challenges: benefit.resourceIds,
  paymentOptions: {
    price: {
      amount: plan?.pricing?.price?.value,
      currency: plan?.pricing?.price?.currency,
    },
    additionalFees: plan.pricing?.feeConfigs?.map(({ fee }) => fee) ?? [],
    recurring: !!plan?.pricing.subscription,
    validFor: {
      forever: false,
      period: {
        amount: plan?.pricing?.subscription?.cycleDuration?.count,
        unit: plan?.pricing?.subscription?.cycleDuration?.unit,
      },
    },
  },
  visible: planInfo.visible || false,
});

const WARMUPDATA_USERS_PP = 'WARMUPDATA_ELIGIBLE_ORDERS';

export const loadUserPaidPlans = async (flowAPI: ControllerFlowAPI) => {
  const warmupData = new WarmupData(flowAPI);
  const ssrData = warmupData.get(WARMUPDATA_USERS_PP);

  try {
    const paidPlansWithBenefits: IBenefitsListResponse =
      ssrData || (await flowAPI.httpClient.request(benefitsList()))?.data;

    warmupData.set(WARMUPDATA_USERS_PP, paidPlansWithBenefits);

    const benefitsActive: BenefitWithPlanInfo[] =
      paidPlansWithBenefits?.benefits?.filter(
        ({ planInfo }) => planInfo.status === PlanStatus.ACTIVE,
      ) || [];

    return benefitsActive
      .map(({ planInfo, benefit }) => {
        const plan = paidPlansWithBenefits.paidPlans.find(({ id }) => {
          return id === planInfo.id;
        });

        return plan ? transformUserPlan(plan, planInfo, benefit) : null;
      })
      .filter((plan) => !!plan);
  } catch (error) {
    handleError({ error, context: 'loadUserPaidPlans' });
    console.error(error);
  }
};
