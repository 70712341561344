import type { Step, StepV3WithDelay, StepWithDelay } from '../types/v3Types';
import type { ChallengeStep } from '@wix/ambassador-challenges-v1-participant/types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';
import { isV3Step } from '../utils/isV3';

export const getTitle = (step: Step) => {
  if (isV3Step(step)) {
    return (step as StepV3).description?.title;
  }
  return (step as ChallengeStep).settings?.general?.description?.title;
};

export const getDelay = (step: StepWithDelay) => {
  if (isV3Step(step)) {
    return (step as StepV3WithDelay).delay;
  }

  return (
    ((step as ChallengeStep).settings?.embedding?.startConditions[0]?.delay
      ?.value as number) || 0
  );
};
