import { ListStepsResponse } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  ParticipantStep,
  ParticipantStepState,
} from '@wix/ambassador-challenges-v1-participant/types';
import { format, addDays } from 'date-fns';
import { getDelay } from '../../selectors/step';

const currDate = new Date();

export const toParticipantSteps = ({
  ownerSteps,
  initialDate = null,
  locale = null,
}: {
  ownerSteps: ListStepsResponse;
  initialDate?: Date;
  locale?: any;
}): ParticipantStep[] => {
  return ownerSteps.steps.map((step) => {
    const delay = getDelay(step);

    const startDate = addDays(initialDate || currDate, delay);
    const finishDate = addDays(initialDate || currDate, delay + 1);

    return {
      id: step?.id,
      dateFrame: {
        start: format(startDate, 'yyyy-MM-dd', {
          locale: locale || null,
        }),
        finish: format(finishDate, 'yyyy-MM-dd', {
          locale: locale || null,
        }),
      },
      source: step,
      transitions: [{ state: ParticipantStepState.RUNNING }],
    };
  });
};
