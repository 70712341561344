import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isPaidPlansInstalled } from './isPaidPlansInstalled';
import { getPaidPlansData, resetPaidPlansCache } from '../data';
import { userProviderPropsMap } from '../../../User/userProviderPropsMap';

export default async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const isPPInstalled = await isPaidPlansInstalled(flowAPI);

    if (isPPInstalled) {
      resetPaidPlansCache();
      await userProviderPropsMap?.cache?.clear();
      flowAPI.controllerConfig.setProps({
        ...(await getPaidPlansData(flowAPI)),
      });
    }
  });
}
