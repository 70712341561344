import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import flatten from 'lodash/flatten';
import {
  getSectionNavigationInfo,
  loadParticipantSections,
  requestParticipantSection,
  requestParticipantStep,
} from './helpers';
import {
  getStepNavigationInfo,
  loadParticipantSteps,
  updateParticipantStepStatus,
} from '../ParticipantStepsDataProvider/helpers';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  getSectionNumberInProgram,
  isSelfPaced,
} from '../../selectors/isSelfPaced';
import memoize from 'lodash/memoize';
import { getChallengeData } from '../storage-contexts/Challenge';

const updateParticipantsSections = async (
  flowAPI: ControllerFlowAPI,
  program: Challenge,
) => {
  const _sections = await loadParticipantSections(flowAPI, program);
  const _steps = flatten(_sections.map((section) => section.steps || []));

  flowAPI.controllerConfig.setProps({
    listParticipantSections: _sections,
    participantSteps: { steps: _steps },
  });
};

const handleParticipantLogin = async (flowAPI: ControllerFlowAPI) => {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const challengeData = await getChallengeData(flowAPI);

    if (isSelfPaced(challengeData?.challenge)) {
      await updateParticipantsSections(flowAPI, challengeData?.challenge);
    } else {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(
          flowAPI,
          challengeData?.challenge,
        ),
      });
    }
  });
};

export const participantSectionsPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  await handleParticipantLogin(flowAPI);
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge;
  const useSections =
    isSelfPaced(challenge) && Boolean(getSectionNumberInProgram(challenge));
  const sections = useSections
    ? await loadParticipantSections(flowAPI, challenge)
    : [];
  const selectedSection = await getSectionNavigationInfo(flowAPI, sections);
  const steps = useSections
    ? flatten(sections.map((section) => section.steps || []))
    : (await loadParticipantSteps(flowAPI, challenge)).steps;
  const { selectedStep } = await getStepNavigationInfo(flowAPI, steps);

  return {
    selectedStep,
    selectedSection,
    participantSteps: { steps },
    listParticipantSections: sections,
    isListParticipantSectionsRequestInProgress: false,
    isParticipantStepsLoading: false,
    updateParticipantSections: () =>
      updateParticipantsSections(flowAPI, challenge),
    updateParticipantSteps: async () => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(flowAPI, challenge),
      });
    },
    updateParticipantStepStatus: async (payload) =>
      updateParticipantStepStatus(flowAPI, payload),
    requestParticipantSection: async (sectionId, sections) =>
      requestParticipantSection(sectionId, sections, flowAPI),
    requestParticipantStep: async (stepId, steps) =>
      requestParticipantStep(stepId, steps, flowAPI),
  };
});
