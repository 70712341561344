import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { SelectedPaymentOption } from '../../components/ChallengesPage/Widget/components/Pricing/interfaces';

import { getParticipant } from './helpers/getParticipant';
import { joinToChallenge } from './helpers/joinUserToChallenge';
import { cancelInvite } from './helpers/cancelInvite';
import { leaveTheChallenge } from './helpers/leaveTheChallenge';

import {
  getUserEmail,
  getUserFromConfig,
  getUserType,
  handleUserLogin,
  promptLogin,
  UpdatedUserData,
  updateUserContext,
} from './helpers/userContextHelpers';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';

import memoize from 'lodash/memoize';
import cloneDeep from 'lodash/cloneDeep';
import { IUserProviderProps } from './UserProvider';
import { isParticipantInLockedState } from './helpers/getStats';
import { leaveProgram } from './helpers/leaveProgram';
import { isMA } from '../../selectors/isMA';
import { isUserJoinedAlready } from './helpers/userTypeHandlers';

// `getParticipant` should be called only for participant-related pages, but other methods can be used at any page (f.ex. MA).

export const userProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IUserProviderProps> {
  const user = await getUserFromConfig(flowAPI.controllerConfig);
  const slug = getChallengeSlugFromLocation(flowAPI);
  const participant =
    slug && !isMA(flowAPI) ? await getParticipant(flowAPI) : null;
  const userType = getUserType(user, participant);

  const userProviderProps: IUserProviderProps = {
    user,
    userType,
    isParticipantInSuspendedState:
      getUserType(user, participant) === ParticipantState.SUSPENDED,
    isParticipantInLockedState:
      flowAPI.environment?.isViewer && isUserJoinedAlready(userType)
        ? await isParticipantInLockedState(flowAPI)
        : false,
    participant,
    async promptLogin(): Promise<any> {
      return promptLogin(flowAPI);
    },
    async join(
      selectedPaymentOption: SelectedPaymentOption,
      startDate?: string,
      settings?: { showOneAppInfo?: boolean },
    ): Promise<void> {
      void joinToChallenge(
        flowAPI,
        userProviderProps,
        selectedPaymentOption,
        startDate,
        settings,
      );
    },
    async cancelJoinRequest(challengeId?: string): Promise<void> {
      await cancelInvite(flowAPI, challengeId);
      if (!isMA(flowAPI)) {
        await userProviderProps.updateParticipant();
      }
    },
    async leaveProgram(
      participantId: string,
      challengeId: string,
    ): Promise<void> {
      return leaveProgram(flowAPI, participantId, challengeId);
    },
    async leaveTheChallenge(
      participantId: string,
      challengeId?: string,
    ): Promise<void> {
      return leaveTheChallenge(
        flowAPI,
        participantId,
        userProviderProps,
        challengeId,
      );
    },
    async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
      const participantCopy = cloneDeep(userProviderProps.participant);
      if (participantCopy?.stepsSummary) {
        participantCopy.stepsSummary.completedStepsNumber =
          participantCopy.stepsSummary.completedStepsNumber + 1;
      }
      return userProviderProps.updateParticipant(participantCopy);
    },
    async updateParticipant(newParticipant): Promise<UpdatedUserData> {
      const userData: Partial<IUserProviderProps> = await updateUserContext(
        flowAPI,
        newParticipant,
      );

      Object.entries(userData).forEach(([key, val]) => {
        userProviderProps[key] = val;
      });

      flowAPI.controllerConfig.setProps(userData);

      return userData;
    },
    async requestMemberEmail() {
      const memberEmail = await getUserEmail(flowAPI.controllerConfig);

      flowAPI.controllerConfig.setProps({
        user: {
          ...user,
          email: memberEmail,
        },
      });
    },
  };

  await handleUserLogin(flowAPI, userProviderProps);

  return userProviderProps;
});
